<template>
    <v-card class='network-type-form'>
        <v-toolbar flat outlined>
            <v-icon @click="close()">mdi-close</v-icon>

            <v-spacer />

            <v-toolbar-title>Edit network type</v-toolbar-title>

            <v-spacer />
            
            <!-- Removed :disabled="!isValid" - engineers want to partial save -->
            <v-btn @click="save()" small text>Save</v-btn>
        </v-toolbar>

        <v-card-text>
            <v-container>
                <v-form
                    ref="form"
                    v-model="isValid"
                >
                    <v-row>
                        <v-select
                            label="Please confirm network type"
                            v-model="networkTypeIdsLocal"
                            :items="lookups.allNetworkTypes"
                            item-text="name"
                            item-value="id"
                            :rules="rules.requiredArray"
                            multiple
                            clearable
                        ></v-select>
                    </v-row>
                    
                    <v-row>
                        <v-select
                            label="Please confirm if network is VMO2 or Nexfibre"
                            v-model="vmo2NexfibreIdLocal"
                            :items="lookups.allVmo2NexfibreOptions"
                            item-text="name"
                            item-value="id"
                            :rules="rules.required"
                            clearable
                        ></v-select>
                    </v-row>
                    
                </v-form>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
import instructionsAgent from "../instructionsAgent.js";
import optionSetAgent from "../../Ods/optionSetAgent.js";

export default {
    props: {
        id: String,

        networkTypeIds: Array,
        vmo2NexfibreId: Number,
    },
    
    data: function () {
        return {
            networkTypeIdsLocal: this.networkTypeIds,
            vmo2NexfibreIdLocal: this.vmo2NexfibreId,
            
            lookups: {
                allNetworkTypes: [],
                allVmo2NexfibreOptions: [],
            },

            isValid: true,

            rules: {
                required: [v => !!v || "Required"],
                requiredArray: [v => v.length > 0 || "Required"],
            }
        };
    },
    
    mounted: function () {
        var l = this.$coreUi.loading();

        Promise.all([
            optionSetAgent.getOptionSetValues("vm-network-type").then(data => {
                this.lookups.allNetworkTypes = data.optionSetValueProperties;
            }).catch(console.error),

            optionSetAgent.getOptionSetValues("vm-network-owner").then(data => {
                this.lookups.allVmo2NexfibreOptions = data.optionSetValueProperties;

                if (this.lookups.allVmo2NexfibreOptions.every(x => x.id !== this.vmo2NexfibreIdLocal))
                    this.vmo2NexfibreIdLocal = null;
            }).catch(console.error),

        ]).then(() => {
            l.dismiss();
            this.refreshValidation();
        });
    },

    methods: {
        refreshValidation () {
            this.$nextTick(() => {
                this.$refs.form.validate();
            });
        },

        save () {
            var l = this.$coreUi.loading();
            
            // Load workItem
            instructionsAgent.getInstruction(this.id).then((result) => {

                // Set fields and save
                result.aspectData.formBuilder.formData["vm-instruction-network-type"] = this.networkTypeIdsLocal.join(",");
                result.aspectData.formBuilder.formData["vm-instruction-vmo2-nexfibre"] = this.vmo2NexfibreIdLocal;

                // Don't invoke these aspect savers (they could fail)
                delete result.aspectData.odsEntityPicker;
                delete result.aspectData.instructionWorkTypeDetails;
                delete result.aspectData.instructionB2BDetails;
                delete result.aspectData.locationCoordinate;
                
                instructionsAgent.saveInstruction(this.id, result).then(() => {

                    l.dismiss();
                    this.close(true);

                }).catch(console.error);

            }).catch(console.error);
        },

        close (result) {
            this.$emit("close", result);
        }
    },
};
</script>
