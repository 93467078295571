import { SharedoFetch } from '@sharedo/mobile-core'

function getOptionSetValues(optionSetName, stripingContext)
{
    var context = _convertToCtx(stripingContext || {});

    var queryString = _toQueryString(context);

    // TODO add get(url, stripingContext) to core
    return SharedoFetch.get(`/api/ods/optionsets/${optionSetName}?${queryString}`);
}


// Similar to jQuery.param()
function _toQueryString(obj)
{
    return Object
        .keys(obj)
        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(obj[k]))
        .join('&');
}

// Prepend 'ctx' to fields (required for striping)
function _convertToCtx(obj)
{
    if (Object.keys(obj).length === 0) return {};

    var objs = Object.keys(obj).map(f => {
        return { ["ctx_" + f]: obj[f] };
    });

    return Object.assign.apply(null, objs)
}

export default 
{
    getOptionSetValues,
};
